/* eslint quote-props: ["error", "consistent"] */
const translations = {

  'it': {
    'about-this-service': 'Il servizio',
    'accessibility': 'Accessibilità dei percorsi',
    'accessibility-limited': 'Percorsi accessibili a persone con difficoltà motorie',
    'accessibility-nolimit': 'Tutti i percorsi',
    'accessible-stop': 'fermata accessibile',
    'not-accessible-stop': 'fermata non accessibile',
    'accessible-vehicle': 'veicolo accessibile',
    'not-accessible-vehicle': 'veicolo non accessibile',
    'add-itinerary-via-point': 'Aggiungi destinazione intermedia',
    'add-location': 'Aggiungi posizione',
    'add-location-to-favourites':
      'Aggiungi una destinazione abituale alla lista dei tuoi preferiti',
    'add-via-button-label': 'Aggiungi una destinazione intermedia',
    'add-via-duration-button-label': 'Imposta durata sosta alla tappa {index}',
    'add-via-duration-button-label-close':
      'Chiudi selettore durata sosta alla tappa {index}',
    'add-via-duration-button-label-open':
      'Apri selettore durata sosta alla tappa {index}',
    'arrive-leave': 'Arrivo o partenza all\'ora selezionata',
    'address': 'Indirizzo',
    'address-or-stop': 'Indirizzo o fermata',
    'agency': 'Servizio a cura di ',
    airplane: 'Aereo',
    'airplane-with-route-number': 'Volo {routeNumber}',
    'airport-check-in': 'Fai il check-in presso {agency}',
    'airport-collect-luggage': 'Recupera i bagagli',
    'airport-security-check-go-to-gate':
      'Effettua il controllo di sicurezza e vai all\'imbarco',
    'all-routes': 'tutte',
    'all-routes-disabled': 'Nessuna linea',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arrivo alle',
    'arriving-soon': 'In arrivo',
    'avoid-transfers': 'Evita',
    'avoid-walking': 'Evita',
    'back-button-title': 'Torna alla pagina precedente',
    'back-to-front-page': 'Torna alla homepage',
    'battery-availability': 'Percentuale di carica della batteria',
    'bicycle':'bici',
    'bicycle-distance-duration': 'Bici {distance} ({duration})',
    'bicycle-withoutBox': 'In bicicletta',
    'bike-availability': 'Biciclette disponibili in questo momento',
    'bike-not-allowed-bus': 'Bici non ammesse sul bus',
    'bike-not-allowed-tram': 'Bici non ammesse sul tram',
    'biketransport-citybike': "Bikesharing",
    'biketransport-only-bike': "Bici propria",
    'biking-amount': 'Tempo in bici',
    'biking-speed': 'Velocità in bicicletta',
    'bus': 'Bus',
    'bus-with-route-number': 'Linea {routeNumber} {headSign}',
    'buy-ticket': 'Acquista il biglietto',
    call: 'Chiama',
    'cancel': 'Annulla',
    'canceled-itineraries-amount':
      'Ancora {itineraryAmount, plural, =1 {1 itinerario cancellato} altri {{itineraryAmount} itinerari cancellati}}',
    'canceled-itineraries-amount-hide':
      'Nascondi itinerari cancellati ({itineraryAmount})',
    'canceled-legs': 'Partenze annullate della linea',
    'car':'auto',
    'car-availability': 'Auto elettriche disponibili in questo momento',
    'car-availability-short': 'Parcheggio LeasysGo!',
    'car-distance-duration': 'In auto {distance} ({duration})',
    'car-withoutBox': 'In auto',
    car_park: 'park & ride',
    'cause': 'Causa',
    'char-left': 'caratteri',
    'choose-stop': 'Scegli',
    'Città di Torino': 'Città di Torino',
    'citybike': '[TO]Bike',
    'citybike-availability-short': '{network}',
    'citybike-distance-duration': 'In bici {distance} ({duration})',
    'citybike-network-headers': 'Bici e monopattini in sharing',
    'citybike-off-season':
      'In questo momento non sono disponibili informazioni sui veicoli in sharing',
    'citybike-register-required': 'Accedi al servizio',
    'citybike_off': 'chiusa',
    'clear-button-label': 'Cancella',
    'close': 'Chiudi',
    'contact-information': 'Contatta {contactName}',
    'create-account': 'Crea un account {contactName}',
    'cycle-distance-duration': 'Pedala {distance} ({duration})',
    'cyclewalk-distance-duration': 'Avanza per {distance} ({duration})',
    currentposition: 'Posizione attuale',
    'datasources': 'Sorgente dati',
    'date': 'Data',
    'delete': 'Cancella',
    'departure-time-in-minutes': '{minutes} min',
    'departures': 'Partenze',
    'description': 'Trova le linee di trasporto vicine a te',
    'destination': 'Destinazione',
    'destination-outside-service':
      'Non è stato possibile trovare un percorso; hai scelto una destinazione esterna all\'area servita da Muoversi a Torino. Cambia destinazione.',
    'destination-placeholder': 'Dove vuoi andare?',
    'destination-label-change': 'Cambia destinazione',
    'dialog-return-to-map': 'Torna alla mappa',
    'digitransit-platform': 'Piattaforma Digitransit',
    'disclaimer': 'Disclaimer',
    disruption: 'Disruption',
    'disruption-info': 'Avvisi e informazioni di servizio',
    'disruption-info-no-alerts': 'Al momento non ci sono deviazioni in corso. Buon viaggio!',
    'disruption-info-route-no-alerts':
      'No known disruptions or diversions to the route.',
    'distance-under': 'Distanza inferiore a {distance} m',
    'disruptions': 'Deviazioni',
    'distance-between': 'Distanza tra {distance1} m e {distance2} m',
    'distance-total': 'Distanza totale',
    'distance-under': 'Dista meno di {distance} m',
    'earlier': 'Prima',
    'edit-favourites': 'Modifica',
    'elevation-gained-total': 'Dislivello in salita',
    'elevation-lost-total': 'Dislivello in discesa',
    'explanations': 'Dettagli',
    'extra-info': 'Informazioni aggiuntive',
    'faq': 'Faq',
    'favourite-target': 'destinazione preferita',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Battello {routeNumber} {headSign}',
    'fetch-new-route': 'Scarica una nuova linea',
    'footer-faq': 'FAQ',
    'footer-feedback': 'footer-feedback',
    'footer-link-to-privacy-policy': 'Privacy',
    'frontpage': 'Torna alla mappa',
    'generic-error': 'Errore generico',
    'geolocate-yourself': 'Scegli una posizione',
    'geolocation-denied-heading': 'Problemi di localizzazione GPS',
    'geolocation-denied-text':
      'Non riesco ad accedere alla tua posizione. Controlla il GPS o le impostazioni del browser.',
    'geolocation-failed-heading': 'Problemi di localizzazione GPS',
    'geolocation-failed-text':
      'Il tuo browser non supporta la geolocalizzazione. Digita un indirizzo.',
    'geolocation-prompt-text':
      'Accetta la richiesta del browser di accedere alla tua posizione.',
    'geolocation-timeout-heading':
      'Sto impiegando troppo tempo per accedere alla posizione.',
    'geolocation-timeout-text':
      'Hai accettato la richiesta del browser di accedere alla posizione?',
    'give-destination': 'Cerca una destinazione, una linea o una fermata',
    'give-name-to-location': 'Assegna un nome a questo indirizzo',
    'give-origin': 'Inserisci il punto di partenza',
    'give-position': 'Inserisci un indirizzo o la tua posizione nel campo di ricerca.',
    'give-route': 'Cerca una linea',
    'guide':'Guida all\'uso',
    'hour': 'Ora',
    'hour-short': 'h',
    'how-to-rate-service': 'Che commento faresti a questo servizio?',
    'https://www.muoversiatorino.it/it/servizio':'https://www.muoversiatorino.it/it/servizio',
    'https://www.muoversiatorino.it/it/domande-frequenti':'https://www.muoversiatorino.it/it/domande-frequenti',
    'https://www.muoversiatorino.it/it/avvertenze':'https://www.muoversiatorino.it/it/avvertenze',
    'https://www.5t.torino.it':'https://www.5t.torino.it',
    'in-addition': 'In più',
    'inquiry': 'Aiutaci a migliorare il servizio: rispondi al questionario',
    'instructions': 'Istruzioni',
    'itinerary-details.biking-leg':
      '{time}. In bici {distance} da {origin} a {destination}; durata {duration}',
    'itinerary-details.car-leg':
      '{time}. In auto {distance} dA {origin} a {destination}; durata {duration}',
    'itinerary-details.end-leg':
      '{time}. Arrivo a destinazione: {destination}.',
    'itinerary-details.route-has-info-alert': 'Avvisi legati alla linea.',
    'itinerary-details.route-has-severe-alert': 'Deviazioni alla linea.',
    'itinerary-details.route-has-unknown-alert': 'Deviazioni alla linea.',
    'itinerary-details.route-has-warning-alert': 'Deviazioni alla linea.',
    'itinerary-details.scooter-leg':
      '{time}. Col monopattino {distance} da {origin} a {destination}; durata {duration}',
    'itinerary-details.transit-leg-part-1': '{time}. Prendi',
    'itinerary-details.transit-leg-part-2':
      'dalla fermata {startStop} {trackInfo} alla fermata {endStop}; durata {duration}',
    'itinerary-details.walk-leg':
      '{time}.  A piedi {distance} da {origin} a {destination}; durata {duration}',

    'itinerary-feedback-button': 'Invia',
    'itinerary-feedback-message': 'Hai trovato quello che cercavi ?',
    'itinerary-feedback-placeholder': 'Descrizione (opzionale)',
    'itinerary-hide-stops':'Nascondi fermate',
    'itinerary-page.description': 'Percorso',
    'itinerary-page.hide-details': 'Nascondi dettagli',
    'itinerary-page.show-details': 'Mostra dettagli',
    'itinerary-page.title': 'Percorso',
    'itinerary-page.update-alert': 'Risultati aggiornati',
    'itinerary-summary-page.description': 'Percorso suggerito',
    'itinerary-summary-page.title': 'Percorso suggerito',
    'itinerary-summary-row.biking-distance':
      'Distanza totale in bici {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Mostra sulla mappa',
    'itinerary-summary-row.description':
      'Percorso con partenza il {departureDate} {departureTime} e arrivo il {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Tempo totale {totalTime}. {distance}',
    'itinerary-summary-row.first-departure':
      '{vehicle} parte alle {departureTime} dalla fermata {stopName}.',
    'itinerary-summary-row.transfers':
      'Trasferimento su {vehicle} alla fermata {stopName}',
    'itinerary-summary-row.walking-distance':
      'Distanza totale a piedi {totalDistance}.',
    'itinerary-summary.show-on-map': 'Mostra su mappa {target}',
    'itinerary-ticket.title': 'Titolo richiesto',
    'itinerary-tickets.title': 'Titoli di viaggio richiesti',
    'itinerary-time.title': 'Durata',
    'itinerary-walk.title': 'a piedi',
    'journeyplanner.title': 'Muoversi a Torino',
    'later': 'Dopo',
    'leaves': 'Alle',
    'leaving-at': 'Partenza alle',
    'likely-to-recommend': 'Consiglieresti questo servizio ad amici e colleghi?',
    'likely-to-use': 'Utilizzeresti questo servizio invece che gli attuali siti di calcolo percorso?',
    'loading': 'Caricamento in corso...',
    'locality': 'località',
    'locate': 'Localizza',
    'location-examples': 'es: casa, ufficio, palestra... ',
    'location-from-map': 'posizione scelta',
    'location-home': 'Casa',
    'location-school': 'Scuola',
    'location-shopping': 'Shopping',
    'location-sport': 'Sport',
    'location-work': 'Lavoro',
    'login': 'Login',
    'long-transfers-margin':'12 min',
    'main-menu-label-close': 'Chiudi il Menu',
    'main-menu-label-open': 'Accedi al Menu',
    'main-mode': "viaggio",
    'map': 'Mappa',
    'map-layer-citybike': 'Sharing mobility',
    'map-layer-bird': 'Bird',
    'map-layer-bit': 'Bit Mobility',
    'map-layer-bolt': 'Bolt',
    'map-layer-boltebike': 'Bolt e-bikes',
    'map-layer-cooltra': 'Cooltra',
    'map-layer-cooltraebike': 'Cooltra e-bikes',
    'map-layer-dott': 'Dott',
    'map-layer-goebike': 'Go e-bikes',
    'map-layer-helbiz': 'Helbiz',
    'map-layer-helbizebike': 'Helbiz e-bikes',
    'map-layer-hive': 'Hive',
    'map-layer-lime': 'Lime',
    'map-layer-limeebike': 'Lime e-bikes',
    'map-layer-link': 'Link',
    'map-layer-voi': 'Voi',
    'map-layer-wind': 'Wind',
    'map-layer-zigzag': 'Zig Zag',
    'map-layer-bluetorino': 'LeasysGo!',
    'map-layer-mimoto': 'Mimoto',
    'map-layer-ridemovibike': 'Ridemovi bikes',
    'map-layer-ridemoviebike': 'Ridemovi e-bikes',
    'map-layer-park-and-ride': 'Parcheggi P&R',
    'map-layer-stop-all': 'Fermate e stazioni',
    'map-layer-stop-bus': 'Fermate bus',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-tram': 'Fermate tram',
    'map-layer-terminal-bus': 'Terminal bus',
    'map-layer-terminal-rail': 'Stazioni ferroviarie',
    'map-layer-terminal-subway': 'Stazioni Metro',
    'map-layer-ticket-sales-machine': 'Bigliettatrici automatiche',
    'map-layer-ticket-sales-point': 'Punti vendita smart card',
    'map-layer-vehicles': 'Mezzi pubblici in tempo reale',
    'messagebar-label-close-message-bar': 'Chiudi il banner',
    'messagebar-label-page': 'Pagina',
    'minute-short': 'min',
    'minutes': 'min',
    'minutes-or-route': 'Minuti / linea',
    'moped-availability-short': 'Scooter {network}',
    'more-settings': 'Altre opzioni',
    'navigate': 'Naviga',
    'near-you': 'Vicino a te',
    'nearest': 'Vicino',
    'neighbourhood': 'quartiere',
    'network-error': 'Errore di rete',
    'next': 'Prossimo',
    'no-bike-allowed-popup-tram-bus':
      'Non è consentito trasportare biciclette a bordo dei bus; Lascia la bici parcheggiata alla fermata o in una rastrelliera. ',
    'no-bike-allowed-popup-train': 'è consentito trasportare biciclette a bordo dei treni regionali.',
    'no-departures': 'Nessun passaggio',
    'no-favourite-locations': 'Aggiungi qui le tue destinazioni preferite e calcola direttamente i tuoi percorsi.',
    'no-favourites': 'Clicca sulla stella accanto alla linea per aggiungerla ai tuoi preferiti. Vedrai qui la lista delle tue linee preferite',
    'no-position': 'Nessuna posizione',
    'no-position-no-stops':
      'Posizione non disponibile. Non riesco a mostrarti le fermate vicine.',
    'no-route-end-date-not-in-range':
      'Gli orari attualmente disponibili non includono la data selezionata.',
    'no-route-msg': 'Spiacenti, non siamo riusciti a trovare un percorso tra i punti indicati; prova a cambiare partenza o destinazione.',
    'no-route-msg-with-changes':
      'Spiacenti, non siamo riusciti a trovare un percorso tra i punti indicati; prova a cambiare impostazioni, partenza o destinazione.',
    'no-route-origin-near-destination':
      'Il punto di arrivo è troppo vicino al punto di partenza.',
    'no-route-start-date-too-early':
      'Gli orari attualmente disponibili non includono la data selezionata.',
    'no-route-start-end': 'Scegli un\'origine e una destinazione.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Nessuna corsa trovata nel giorno selezionato',
    'now': 'Adesso',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Nessuna fermata} one {1 fermata} other {{number} fermate} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuti} one {1 minuto} other {{number} minuti}}',
    'off-canvas-info':
      'Effettua il Log in per salvare i tuoi preferiti e utilizzarli anche su altri devices',
    'option-default': 'Normale',
    'option-least': 'Meno possibile',
    'option-less': 'Pochi',
    'option-more': 'Alcuni',
    'option-most': 'Tanti',
    or: 'oppure',
    'origin': 'Partenza',
    'origin-outside-service':
      'Non è stato possibile trovare un percorso; hai scelto una punto di partenza esterno all\'area servita da Muoversi a Torino. Cambia punto di partenza.',
    'own-position': 'La tua posizione',
    'page-not-found': 'Pagina non trovata',
    'park-and-ride': 'Park & Ride',
    'park-and-ride-availability': 'Disponibilità di parcheggio',
    'pay-attention': 'N.B.',
    'pick-icon': "Scegli un'icona",
    'pick-mode': 'Scegli un modo di trasporto',
    'pick-mode-airplane-disabled': 'Aereo non abilitato',
    'pick-mode-airplane-enabled': 'Aereo abilitato',
    'pick-mode-bus-disabled': 'Bus non abilitato',
    'pick-mode-bus-enabled': 'Bus abilitato',
    'pick-mode-citybike-disabled': '[TO]bike non abilitato',
    'pick-mode-citybike-enabled': '[TO]bike abilitato',
    'pick-mode-ferry-disabled': 'Ferry non abilitato',
    'pick-mode-ferry-enabled': 'Ferry abilitato',
    'pick-mode-rail-disabled': 'Treno non abilitato',
    'pick-mode-rail-enabled': 'Treno abilitato',
    'pick-mode-selected': 'Modi di trasporto abilitati',
    'pick-mode-subway-disabled': 'Metro non abilitata',
    'pick-mode-subway-enabled': 'Metro abilitata',
    'pick-mode-tram-disabled': 'Tram non abilitato',
    'pick-mode-tram-enabled': 'Tram abilitato',
    'place-route-or-keyword': 'Destinazione, linea o nome fermata',
    'platform-num': 'Fermata {platformCode}',
    'platform-short': 'Piattaforma',
    'portaleWeb': 'Portale Web',
    'prefer-walking': 'Cammino volentieri',
    preferred: 'Linee preferite',
    'previous': 'Precedente',
    'print': 'Stampa',
    'print-route-app-title': 'Muoversi a Torino',
    'print-timetable': 'Weekly timetable',
    'public-transport': 'Trasporto pubblico',
    'rail': 'Treno',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    realtime: 'in tempo reale',
    'realtime-matching': 'Integrazione col dato in tempo reale',
    'region': 'provincia',
    releases: 'Rilasci',
    'remove-via-button-label': 'Rimuovi tappa {index}',
    'rent-cycle-at': 'Prendi una bicicletta alla stazione {station}',
    'rent-scooter-at': 'Prendi il monopattino {station}',
    'return-cycle-to': 'Posa la bicicletta alla stazione {station}',
    'return-scooter-to': 'Posa il monopattino {station}',
    'right-now': 'Tempo reale',
    'route': 'Linea',
    'route-custom-settings': 'Impostazioni personalizzate',
    'route-default': 'Impostazioni predefinite',
    'route-destination-arrives': 'Ultima fermata',
    'route-fastest': 'Percorso più veloce',
    'route-from-here': "Parto da qui",
    'route-guide': 'Percorso della linea',
    'route-here': 'Arrivo qui',
    'route-least-elevation-changes': 'Evita salite e discese',
    'route-least-transfers': 'Limita trasbordi',
    'route-least-walking': 'Limita tratti a piedi',
    'route-not-possible': 'Impossibile calcolare il percorso : "{error}"',
    'route-page.description': 'Linea {shortName}, {longName}',
    'route-page.title': 'Linea {shortName}',
    'route-page.title-short': 'Linea',
    'route-prefer-greenways': 'Preferisci le piste ciclabili',
    'route-prefer-illuminated': 'Preferisci percorsi illuminati',
    'route-prefer-paved': 'Preferisci strade asfaltate',
    'route-prefer-walking-routes': 'Preferisci percorsi pedonali',
    'route-prefer-winter-maintenance': 'Prefer routes with winter maintenance',
    'route-preferences': 'Tratti in bicicletta',
    'route-public-transport-with-bicycle': 'Trasporto pubblico e bicicletta',
    'route-saved-settings': 'Preferenze salvate',
    'route-stop-or-keyword': 'Linea o nome fermata',
    'routes': 'Linee',
    'routes-platforms': 'Linee / Percorsi',
    'routes-tracks': 'Linee / Percorsi',
    'run': 'Veloce',
    'save': 'Salva',
    'scooter-availability': 'Monopattino disponibile',
    'scooter-availability-short': 'Monopattino {network}',
    'scooter-distance-duration':
      'Pecorri in monopattino {distance} ({duration})',
    'scooter-register-required': 'Accedi al servizio',
    'scooterwalk-distance-duration':
      'Percorri {distance} scendendo dal monopattino ({duration})',
    'search': 'Cerca',
    'search-autosuggest-label':
      'Indirizzi, punti di interesse, linee o fermate. Naviga la lista con i tasti freccia e seleziona con il tasto invio',
    'search-autosuggest-len': ' There are {len} Suggestions available',
    'select-date': 'Seleziona la data',
    'set-time-earlier-button-label': 'Più presto',
    'set-time-later-button-label': 'Più tardi',
    'set-time-now-button-label': 'Ora',
    'time-navigation-buttons': 'pulsanti impostazione ora',
    'search-button-label': 'Cerca',
    'search-destination': 'Cerca una destinazione, una linea o una fermata',
    'search-destination-results-but-no-search':
      'View results in the adjacent “Destination” tab',
    'search-no-results': 'No location',
    'search-origin': 'indirizzo di partenza, linea o fermata',
    'search-position': 'Ricerca la tua posizione',
    'search-search-results-but-no-destination':
      'View results in the adjacent “About the route or stop” tab',
    'searching-position': 'Localizzazione in corso...',
    'select-map-layers-header': 'Cosa visualizzare sulla mappa',
    'select-position': 'Utilizza una posizione che hai cercato precedentemente',
    'send': 'Invia',
    'settings': 'Preferenze',
    'settings-label-change': 'Cambia impostazioni',
    'settings-loadbutton': 'Ricarica le impostazioi salvate',
    'settings-loaded': 'Impostazioni caricate!',
    'settings-reset': 'Torna alle impostazioni iniziali',
    'settings-savebutton': 'Salva le impostazioni',
    'settings-saved': 'Impostazioni salvate!',
    'share': 'Condividi',
    'show-departures': 'Mostra partenze',
    'show-more': 'Mostra di più',
    'show-routes': 'Mostra linee',
    'skip-positioning': 'Ignora il posizionamento GPS',
    'skip-to-content': 'vai al contenuto',
    'slow': 'Lento',
    'specify-location': 'Cerca un indirizzo',
    'splash-choose': 'Imposta posizione',
    'splash-locating': 'in attesa di ricevere la posizione GPS...',
    'splash-or-choose': 'oppure scegliere tra queste opzioni:',
    'splash-use-positioning': 'Utilizza la tua posizione',
    'splash-welcome': 'Benvenuto su Muoversi a Torino!',
    'splash-you-can-also': 'puoi anche',
    'stop': 'Fermata',
    'stop-number': "Codice fermata",
    'stop-page.description': 'Fermata {name} {code}',
    'stop-page.title': 'Fermata {name} {code}',
    'stop-page.title-short': 'Fermata',
    'stops': 'Fermate',
    'street-mode-bicycle': 'in bici',
    'street-mode-car': 'in auto',
    'street-mode-car_park': 'Park & ride',
    'street-mode-public_transport': 'in bus',
    'street-mode-walk': 'a piedi',
    street: 'Street',
    'subway': 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Itinerario n. {number}',
    'summary-page.title': 'Percorsi suggeriti',
    'swap-order-button-label': 'Inverti origine e destinazione',
    'terminal-page.description': 'Terminal {stop_name}',
    'terminal-page.title': 'Terminal {stop_name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Termini d\'uso',
    'ticket-and-price-info': 'info biglietti',
    'tickets': 'Biglietti',
    'time': 'Orario',
    'time-selector-form': 'Modifica orario',
    'time-selector-hours-label': 'Ore',
    'time-selector-minutes-label': 'Minuti',
    'timetable': 'Orari',
    'to-stop': 'Dist',
    'today': 'oggi',
    'tomorrow': 'domani',
    'tram': 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    'transfers': 'Cambi',
    'transfers-allowed': 'Consenti',
    'transfers-margin': 'Margine sui trasbordi',
    'travel-time': '{min} min',
    'travel-time-label': 'Tempo di viaggio',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'Emissioni di CO2',
    'trip-information': 'info corsa',
    'trip-page.description': 'Linea {route_short_name} - {route_long_name}',
    'trip-page.title': 'Linea {route_short_name}',
    'trip-full': 'Il mezzo ha raggiunto la sua capacità massima.',
    'try-again': 'Torna alla mappa',
    unpreferred: 'Evita queste linee',
    'use-citybike': 'TO BIKE',
    'use-own-position': 'Utilizza la tua posizione',
    'using-modes': 'quali mezzi voglio usare',
    venue: 'Punto di interesse',
    poi: 'Punto di interesse',
    'very-likely': 'sicuramente!',
    'very-unlikely': 'per niente',
    'via-leg-stop-duration': 'durata pausa {stayDuration}',
    'via-point-index': 'destinazione intermedia {index}',
    'via-point': 'Destinazione intermedia',
    'viapoint-slack-amount': 'Durata',
    'view-route': 'info linea',
    wait: 'Attendi',
    'wait-amount-of-time': 'Attendi {duration}',
    'walk':'a piedi',
    'walk-distance-duration': 'A piedi {distance} ({duration})',
    'walking': 'Tratti a piedi',
    'walking-speed': 'Velocità a piedi',
    'warning-call-agency':
      'Only on demand: {routeName}, which needs to be booked in advance.',
    'warning-call-agency-no-route':
      'Only on demand. Needs to be booked in advance.',
    'weather-at-destination': 'Meteo a destinazione',
    'your-favourites': 'Preferiti',
    'zones': 'Zone tariffarie',
  },

  'en': {
    'about-this-service': 'About the service',
    'accessibility': 'Routes accessible to people with limited mobility',
    'accessibility-limited': 'Wheelchair',
    'accessibility-nolimit': 'No limits',
    'accessible-stop': 'Accessible stop',
    'not-accessible-stop': 'Not accessible stop',
    'accessible-vehicle': 'accessible vehicle',
    'not-accessible-vehicle': 'not accessible vehicle',
    'add-itinerary-via-point': 'Add via point for itinerary',
    'add-location': 'Add location',
    'add-location-to-favourites': 'Add an important location to your Favorites',
    'add-via-button-label': 'Add via point',
    'add-via-duration-button-label': 'Set stop duration at via point {index}',
    'add-via-duration-button-label-close':
      'Close stop duration selector at via point {index}',
    'add-via-duration-button-label-open':
      'Open stop duration selector at via point {index}',
    'arrive-leave': 'Arrive or leave at selected time',
    'search-autosuggest-label':
      'Venue, place and stopsearch. Navigate list with arrow keys and select with enter key',
    'search-autosuggest-len': 'There are {len} Suggestions available',
    'select-date': 'Select date',
    'set-time-earlier-button-label': 'Set travel time to earlier',
    'set-time-later-button-label': 'Set travel time to later',
    'set-time-now-button-label': 'Set travel time to current',
    'time-navigation-buttons': 'Time navigation buttons',
    'time-selector-form': 'Edit time',
    // eslint-disable-next-line sort-keys
    address: 'Address',
    'address-or-stop': 'Address or stop',
    agency: 'Operator',
    airplane: 'Flight',
    'airplane-with-route-number': 'Flight {routeNumber}',
    'airport-check-in': 'Check-in at the {agency} desk',
    'airport-collect-luggage': 'Collect your luggage',
    'airport-security-check-go-to-gate':
      'Proceed to your gate through security check',
    'all-routes': 'All routes',
    'all-routes-disabled': 'No routes',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arriving',
    'arriving-soon': 'Now',
    'at-time': 'at',
    'avoid-transfers': 'Avoid transfers',
    'avoid-walking': 'Avoid walking',
    'back-button-title': 'Go back to previous page',
    'back-to-front-page': 'Back to the front page',
    'battery-availability': 'Battery charge percentage',
    bicycle: 'bike',
    'bicycle-distance-duration': 'Bike {distance} ({duration})',
    'bike-availability': 'Bikes available at the station right now',
    'bike-availability-short': 'Bike  {network}',
    'bike-not-allowed-bus': 'Bikes are not allowed on the bus',
    'bike-not-allowed-tram': 'Bikes are not allowed on the tram',
    'biketransport-citybike': "I'm using a citybike",
    'biketransport-only-bike': "I'm travelling only by bike",
    'biking-amount': 'Amount of biking',
    'biking-speed': 'Biking speed',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'How to buy a ticket',
    call: 'Call',
    cancel: 'Cancel',
    canceled: 'Canceled',
    'canceled-itineraries-amount':
      'Additional {itineraryAmount, plural, =1 {1 canceled itinerary} other {{itineraryAmount} canceled itineraries}}',
    'canceled-itineraries-amount-hide':
      'Hide canceled itineraries ({itineraryAmount})',
    'canceled-legs': 'Canceled departures on the route',
    car: 'Car',
    'car-distance-duration': 'Drive {distance} ({duration})',
    car_park: 'park & ride',
    cause: 'cause',
    'char-left': 'characters',
    'choose-stop': 'Select',
    citybike: 'City bike',
    'citybike-availability-short': '{network}',
    'citybike-distance-duration': 'Bike {distance} ({duration})',
    'citybike-network-headers': 'Citybikes and scooters',
    'citybike-off-season':
      'City bike stations will be opened again next spring',
    'citybike-register-required': 'Access the service app',
    citybike_off: 'Closed',
    'clear-button-label': 'Clear',
    close: 'Close',
    'contact-information': '{contactName} contact details',
    continue: 'Continue',
    'create-account': 'Create {contactName} account',
    'cycle-distance-duration': 'Cycle {distance} ({duration})',
    'cyclewalk-distance-duration': 'Walk your bike {distance} ({duration})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Current position',
    datasources: 'Data sources',
    date: 'Date',
    delete: 'Delete',
    'departure-is-canceled': '{modeInfo} {from}–{to} at {time} is cancelled',
    'departure-is-canceled-airplane': 'Flight {shortName}',
    'departure-is-canceled-bus': 'Bus {shortName}',
    'departure-is-canceled-ferry': 'Ferry {shortName}',
    'departure-is-canceled-rail': '{shortName} train',
    'departure-is-canceled-subway': 'Metro service {shortName}',
    'departure-is-canceled-tram': 'Tram {shortName}',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Departures',
    description: 'Find public transport services to locations near and far.',
    destination: 'Destination',
    'destination-outside-service':
      'Unfortunately no routes were found for your journey. Your destination address is located outside the service area. Please change it.',
    'destination-placeholder': 'Enter destination, route or stop',
    'dialog-return-to-map': 'Return to map',
    'digitransit-platform': 'Digitransit service platform',
    // disclaimer: 'Please note that the results are based on estimated travel times. The suggested transport connections cannot be guaranteed.',
    disclaimer: 'Disclaimer',
    disruption: 'Disruption',
    'disruption-info': 'Disruption info',
    'disruption-info-no-alerts': 'No known disruptions or diversions.',
    'disruption-info-route-no-alerts':
      'No known disruptions or diversions to the route.',
    disruptions: 'Disruptions',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-total': 'Total distance',
    'distance-under': 'Distance less than {distance} m',
    earlier: 'Earlier',
    'edit-favourites': 'Edit the location in the Favorites',
    'elevation-gained-total': 'Elevation gained',
    'elevation-lost-total': 'Elevation lost',
    explanations: 'Explanations',
    'extra-info': 'Further information',
    'favourite-target': 'Favorite location',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    'fetch-new-route': 'Fetch a new route',
    'footer-faq': 'FAQ',
    'footer-feedback': 'Submit feedback',
    'footer-link-to-privacy-policy': 'Disclaimer',
    frontpage: 'Back to map',
    'generic-error': 'There was an error',
    'geolocate-yourself': 'Detect your location',
    'geolocation-denied-heading': 'Geolocation denied',
    'geolocation-denied-text':
      'You have not given a permission to use your geolocation. You can allow it from the phone or browser settings.',
    'geolocation-failed-heading': 'Geolocation failed',
    'geolocation-failed-text':
      'Your browser does not support location retrieval.',
    'geolocation-prompt-text':
      'Accept your browser’s request to access your location.',
    'geolocation-timeout-heading':
      'Detecting your location is taking longer than expected.',
    'geolocation-timeout-text':
      'Have you accepted your browser’s request to access your location?',
    'give-destination': 'Enter your destination',
    'give-name-to-location': 'Give the location a descriptive name',
    'give-origin': 'Enter your origin.',
    'give-position': 'Enter your location or origin in the search field.',
    'give-route': 'Search route',
    'guide': 'User guide',
    hour: 'Hour',
    'hour-short': 'h',
    'how-to-rate-service': 'How would you rate the service?',
    'https://www.muoversiatorino.it/it/servizio':'https://www.muoversiatorino.it/en/about',
    'https://www.muoversiatorino.it/it/domande-frequenti':'https://www.muoversiatorino.it/en/faq-2',
    'https://www.muoversiatorino.it/it/avvertenze':'https://www.muoversiatorino.it/en/disclaimer-2',
    'https://www.5t.torino.it':'https://www.5t.torino.it/en/',
    'in-addition': 'In addition',
    inquiry: 'How did you find the new Journey Planner? Please tell us!',
    instructions: 'Instructions',
    'itinerary-details.biking-leg':
      'At {time} cycle {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.car-leg':
      'At {time} drive {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.end-leg':
      'At {time} arrive to destination {destination}.',
    'itinerary-details.route-has-info-alert': 'Route has alerts.',
    'itinerary-details.route-has-severe-alert': 'Route has disruptions.',
    'itinerary-details.route-has-unknown-alert': 'Route has disruptions.',
    'itinerary-details.route-has-warning-alert': 'Route has disruptions.',
    'itinerary-details.scooter-leg':
      'At {time} ride your kick scooter {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.transit-leg-part-1': 'At {time} take',
    'itinerary-details.transit-leg-part-2':
      'from stop {startStop} {trackInfo} to stop {endStop}. Estimated duration {duration}',
    'itinerary-details.walk-leg':
      'At {time} walk {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Couldn’t find what you were looking for?',
    'itinerary-feedback-placeholder': 'Description (optional)',
    'itinerary-hide-stops': 'Hide stops',
    'itinerary-page.description': 'Itinerary',
    'itinerary-page.hide-details': 'Hide itinerary details',
    'itinerary-page.show-details': 'Show itinerary details',
    'itinerary-page.title': 'Itinerary',
    'itinerary-page.update-alert': 'Search results updated',
    'itinerary-summary-page.description': 'Route suggestions',
    'itinerary-summary-page.title': 'Route suggestions',
    'itinerary-summary-row.biking-distance':
      'Total biking distance {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Show on map',
    'itinerary-summary-row.description':
      'Itinerary departing at {departureDate} {departureTime} and arriving at {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Total time {totalTime}. {distance}',
    'itinerary-summary-row.first-departure':
      '{vehicle} leaves at {departureTime} from stop {stopName}.',
    'itinerary-summary-row.transfers':
      'Transfer to {vehicle} on stop {stopName}',
    'itinerary-summary-row.walking-distance':
      'Total walking distance {totalDistance}.',
    'itinerary-summary.show-on-map': 'Show on map {target}',
    'itinerary-ticket.title': 'Required ticket',
    'itinerary-tickets.title': 'Required tickets',
    'itinerary-time.title': 'Duration',
    'itinerary-walk.title': 'Total walking distance',
    'journeyplanner.title': 'HSL Journey Planner',
    later: 'Later',
    leaves: 'Leaves',
    'leaving-at': 'Leaving at',
    'likely-to-recommend':
      'How likely are you to recommend our service to your friends or colleagues?',
    'likely-to-use':
      'How likely are you to use this service rather than the current Journey Planner?',
    loading: 'Loading...',
    locate: 'Detect location',
    'location-examples': 'e.g. Home, Work, School,...',
    'location-from-map': 'Selected location',
    'location-home': 'Home',
    'location-school': 'School',
    'location-shopping': 'Shopping',
    'location-sport': 'Sports',
    'location-work': 'Work',
    login: 'Log in',
    logout: 'Log out',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Close the main menu',
    'main-menu-label-open': 'Open the main menu',
    'main-mode': "I'm traveling by",
    map: 'Map',
    'map-layer-citybike': 'Sharing mobility',
    'map-layer-park-and-ride': 'Park & ride sites',
    'map-layer-stop-bus': 'Bus stops',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-tram': 'Tram stops',
    'map-layer-terminal-bus': 'Bus terminals',
    'map-layer-terminal-rail': 'Train stations',
    'map-layer-terminal-subway': 'Metro stations',
    'map-layer-ticket-sales-machine': 'Ticket machines',
    'map-layer-ticket-sales-point': 'Travel Card top up points',
    'map-layer-vehicles': 'Vehicles',
    'messagebar-label-close-message-bar': 'Close banner',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Route',
    'more-settings': 'More settings',
    navigate: 'Navigate',
    'near-you': 'Near you',
    nearest: 'Nearest',
    'network-error': 'Network error',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Neighbourhood ',
    next: 'Next',
    'no-bike-allowed-popup-train':
      'Bicycles are allowed on commuter trains but on long distance trains you have to pay an extra fee and it might require a reservation.',
    'no-bike-allowed-popup-tram-bus':
      'Bicycles are not allowed on buses or trams. If you are using these vehicles on your route, leave the bicycle at the stop or at a Park-and-Ride. ',
    'no-departures': 'No departures',
    'no-favourite-locations':
      'Add your most used locations here. The buttons will plan your route straight away.',
    'no-favourites':
      'Use star button to add routes to your favorites. Your favorite routes are shown on this page.',
    'no-position': 'No location',
    'no-position-no-stops':
      'Your location is not available. We are unable to show the closest stops.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Unfortunately no routes were found for your journey. Try changing your origin or destination.',
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'The origin and the destination are close to each other. Please change either location.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Please select origin and destination.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'No journeys found for the selected date.',
    now: 'Now',
    'number-of-intermediate-stops':
      '{number, plural, =0 {No stops} one {1 stop} other {{number} stops} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minutes} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      'Log in to the service to save your favorites and utilize them on other devices',
    'option-default': 'Default',
    'option-least': 'Least',
    'option-less': 'Less',
    'option-more': 'More',
    'option-most': 'Most',
    or: 'or',
    origin: 'Origin',
    'origin-outside-service':
      'Unfortunately no routes were found for your journey. Your origin address is located outside the service area, please change it.',
    'origin-placeholder': 'From? - address or stop',
    'own-position': 'Your current location',
    'page-not-found': 'The page cannot be found.',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Spaces available',
    'pay-attention': 'N.B.',
    'pick-icon': 'Select icon',
    'pick-mode': 'Select a transport mode',
    'pick-mode-airplane-disabled': 'Flight not selected',
    'pick-mode-airplane-enabled': 'Flight selected',
    'pick-mode-bus-disabled': 'Bus not selected',
    'pick-mode-bus-enabled': 'Bus selected',
    'pick-mode-citybike-disabled': 'Citybike not selected',
    'pick-mode-citybike-enabled': 'Citybike selected',
    'pick-mode-ferry-disabled': 'Ferry not selected',
    'pick-mode-ferry-enabled': 'Ferry selected',
    'pick-mode-rail-disabled': 'Rail not selected',
    'pick-mode-rail-enabled': 'Rail selected',
    'pick-mode-selected': 'Selected transport modes',
    'pick-mode-subway-disabled': 'Metro not selected',
    'pick-mode-subway-enabled': 'Metro selected',
    'pick-mode-tram-disabled': 'Tram not selected',
    'pick-mode-tram-enabled': 'Tram selected',
    'place-route-or-keyword': 'Destination, route or stop',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'portaleWeb': 'Web Portal',
    'prefer-walking': 'Prefer walking',
    preferred: 'Prefer routes',
    previous: 'Previous',
    print: 'Print',
    'print-timetable': 'Weekly timetable',
    'public-transport': 'Public transport',
    rail: 'Train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    realtime: 'Real-time',
    'realtime-matching': 'Real-time integration',
    releases: 'Releases',
    'remove-via-button-label': 'Remove via point {index}',
    'rent-cycle-at': 'Rent a bike at {station} station',
    'rent-scooter-at': 'Rent a kick scooter at {station} station',
    'required-ticket': 'Ticket required for the journey',
    'return-cycle-to': 'Return the bike to {station} station',
    'return-scooter-to': 'Return the kick scooter to {station} station',
    'right-now': 'Right now',
    route: 'Route',
    'route-add-viapoint': 'Via point',
    'route-custom-settings': 'Custom settings',
    'route-default': 'Default settings',
    'route-destination-arrives': 'Drop-off only',
    'route-destination-endpoint': 'Arrives / Terminus',
    'route-fastest': 'Fast route',
    'route-from-here': 'Origin',
    'route-guide': 'Route guide',
    'route-here': 'Destination',
    'route-least-elevation-changes': 'Avoid changes in elevation',
    'route-least-transfers': 'Avoid transfers',
    'route-least-walking': 'Avoid walking',
    'route-not-possible':
      'Unfortunately, your route is not available. Technical error: "{error}"',
    'route-page.description': 'Route {shortName} - {longName}',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Route',
    'route-prefer-greenways': 'Prefer cycleways',
    'route-prefer-illuminated': 'Prefer illuminated routes',
    'route-prefer-paved': 'Prefer paved routes',
    'route-prefer-walking-routes': 'Prefer walking routes',
    'route-prefer-winter-maintenance': 'Prefer routes with winter maintenance',
    'route-preferences': 'Route preferences',
    'route-public-transport-with-bicycle': 'Public transport with bicycle',
    'route-saved-settings': 'Saved settings',
    'route-stop-or-keyword': 'About the route or stop',
    routes: 'Routes',
    'routes-platforms': 'Routes, platforms',
    'routes-tracks': 'Routes, tracks',
    run: 'Run',
    save: 'Save',
    'scooter-availability': 'Kick scooters available at the station right now',
    'scooter-availability-short': 'Kick scooter  {network}',
    'scooter-distance-duration':
      'Ride your kick scooter {distance} ({duration})',
    'scooter-register-required': 'To use kick scooters, you need to register',
    'scooterwalk-distance-duration':
      'Walk your kick scooter {distance} ({duration})',
    search: 'Search',
    'search-button-label': 'Search',
    'search-destination': 'Search destination, route or stop',
    'search-destination-results-but-no-search':
      'View results in the adjacent “Destination” tab',
    'search-no-results': 'No location',
    'search-origin': 'Search origin, route or stop',
    'search-position': 'Detect location',
    'search-search-results-but-no-destination':
      'View results in the adjacent “About the route or stop” tab',
    'searching-position': 'Detecting location...',
    'select-map-layers-header': 'What should be shown on the map?',
    'select-position': 'Select your location from your previous searches',
    send: 'Send',
    'separate-ticket-required':
      'A separate ticket is required for this part of the itinerary.',
    'separate-ticket-required-disclaimer':
      'This itinerary contains one or more legs for which {agencyName} does not sell tickets.',
    settings: 'Settings',
    'settings-label-change': 'Change settings',
    'settings-loadbutton': 'Load settings',
    'settings-loaded': 'Settings loaded!',
    'settings-reset': 'Reset settings',
    'settings-savebutton': 'Save settings',
    'settings-saved': 'Changes saved!',
    share: 'Share itinerary',
    show: 'Show',
    'show-departures': 'Show departures',
    'show-more': 'Show more',
    'show-routes': 'Choose routes',
    'skip-positioning': 'Skip',
    'skip-to-content': 'Skip to content',
    slow: 'Slow',
    'specify-location': 'Specify location',
    'splash-choose': 'Specify origin',
    'splash-locating': 'Detecting location',
    'splash-or-choose': 'or select your origin',
    'splash-use-positioning': 'Use location services',
    'splash-welcome': 'How do you wish to start?',
    'splash-you-can-also': 'or',
    station: 'Station',
    stop: 'Stop',
    'stop-number': 'Stop number',
    'stop-page.description': 'Stop {name} - {code}',
    'stop-page.title': 'Stop {name} - {code}',
    'stop-page.title-short': 'Stop',
    'stop-virtual-monitor': 'Virtual monitor',
    stops: 'Stops',
    'street-mode-bicycle': 'Bicycle',
    'street-mode-car': 'Car',
    'street-mode-car_park': 'Park & ride',
    'street-mode-public_transport': 'Public transport',
    'street-mode-walk': 'Walking',
    // eslint-disable-next-line sort-keys
    street: 'Street',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Itinerary suggestion {number}',
    'summary-page.title': 'Itinerary suggestions',
    'swap-order-button-label': 'Reverse origin and destination',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Terms of Use',
    'ticket-and-price-info': 'Ticket and price information',
    'ticket-single-adult': 'Adult',
    'ticket-type-none': 'No fare zone limits',
    tickets: 'Tickets',
    time: 'Time',
    'time-selector-hours-label': 'Hour',
    'time-selector-minutes-label': 'Minute',
    timetable: 'Timetable',
    'to-stop': 'To stop',
    today: 'Today',
    tomorrow: 'Tomorrow',
    'track-num': 'Track {platformCode}',
    'track-short': 'Track {platformCode}',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfers: 'Number of transfers',
    'transfers-allowed': 'Transfers allowed',
    'transfers-margin': 'Transfer margin at least',
    'travel-time': '{min} min',
    'travel-time-label': 'Travel time',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'CO2 emissions of the journey',
    'trip-information': 'Trip information',
    'trip-page.description': 'Route {route_short_name} - {route_long_name}',
    'trip-page.title': 'Route {route_short_name}',
    'trip-full': 'The vehicle is full',
    'try-again': 'Try again',
    unpreferred: 'Avoid routes',
    'use-citybike': 'Start using',
    'use-national-service':
      'You can also try the national service available at',
    'use-own-position': 'Use current location',
    'using-modes': 'I want to travel mostly by',
    'very-likely': 'Very likely',
    'very-unlikely': 'Very unlikely',
    'via-leg-stop-duration': 'At via point {stayDuration}',
    'via-point': 'Via point',
    'via-point-index': 'Via point {index}',
    'viapoint-slack-amount': 'Stop duration',
    'view-route': 'View Route',
    // eslint-disable-next-line sort-keys
    venue: 'Venue',
    wait: 'Wait',
    'wait-amount-of-time': 'Wait {duration}',
    walk: 'walking',
    'walk-distance-duration': 'Walk {distance} ({duration})',
    walking: 'Amount of walking',
    'walking-speed': 'Walking speed',
    'warning-call-agency':
      'Only on demand: {routeName}, which needs to be booked in advance.',
    'warning-call-agency-no-route':
      'Only on demand. Needs to be booked in advance.',
    'weather-at-destination': 'Weather at the destination',
    yesterday: 'Yesterday',
    'your-favourites': 'Favorites',
    zone: 'Zone',
    zones: 'Fare zones',
  },

  'fr': {
    'about-this-service': 'Le service',
    'accessibility': 'Lignes accessibles aux personnes à mobilité réduite',
    'accessibility-limited': 'Wheelchair',
    'accessibility-nolimit': 'No limits',
    'accessible-stop': 'Arrêt accessible',
    'not-accessible-stop': 'Arrêt non accessible',
    'accessible-vehicle': 'véhicule accessible',
    'not-accessible-vehicle': 'véhicule non accessible',
    'add-itinerary-via-point': 'Ajouter étape',
    'add-location': 'Ajouter un endroit',
    'add-location-to-favourites':
      "Ajouter vos lieux importants à l'onglet favoris",
    address: 'Adresse',
    'address-or-stop': 'Adresse ou arrêt',
    agency: 'Transporteur',
    airplane: 'Avion',
    'airplane-with-route-number': 'Avion {routeNumber}',
    'airport-check-in': "S'enregistrer au guichet {agency}",
    'airport-collect-luggage': 'Récupérer vos bagages',
    'airport-security-check-go-to-gate':
      "Franchir les contrôles de sécurité et se rendre à la porte d'embarquement",
    'all-routes': 'Toutes lignes',
    'all-routes-disabled': 'Aucune ligne',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arrivée',
    'arriving-soon': 'Imm.',
    'avoid-transfers': 'Éviter les correspondances',
    'avoid-walking': 'Éviter la marche',
    'back-button-title': 'Revenir à la page précédente',
    'back-to-front-page': "Revenir à la page d'accueil",
    bicycle: 'Vélo',
    'bicycle-distance-duration': 'Vélo {distance} ({duration})',
    'bike-availability': 'Vélos disponibles en station',
    'bike-availability-short': 'Vélo  {network}',
    'bike-not-allowed-bus': 'Les vélos ne sont pas autorisés dans le bus',
    'bike-not-allowed-tram': 'Les vélos ne sont pas autorisés dans le tram',
    'biketransport-citybike': "J'utilise un vélo en libre service",
    'biketransport-only-bike': "J'utilise uniquement le vélo",
    'biking-amount': 'Quantité de vélo',
    'biking-speed': 'Vitesse en vélo',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'Acheter un titre de transport',
    call: 'Appeler',
    cancel: 'Annuler',
    car: 'Voiture',
    'car-distance-duration': 'Conduire {distance} ({duration})',
    car_park: 'parc relais',
    cause: 'Cause',
    'char-left': 'marquer',
    'choose-stop': 'Merci de choisir la ligne',
    citybike: 'Vélo en libre service',
    'citybike-availability-short': '{network}',
    'citybike-distance-duration': 'VLS {distance} ({duration})',
    'citybike-register-required':
      "L'utilisation du Vélo en libre service nécessite une inscription",
    citybike_off: 'Fermé',
    close: 'Fermer',
    'contact-information': 'Contacter {contactName}',
    'create-account': 'Créer un identifiant {contactName}',
    'cycle-distance-duration': 'Pédaler {distance} ({duration})',
    'cyclewalk-distance-duration':
      'Avancer pied à terre sur {distance} ({duration})',
    datasources: 'Sources des données',
    date: 'Date',
    delete: 'Supprimer',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Départs',
    description:
      'Trouvez un réseau de transport public pour voyager loin ou à proximité',
    destination: 'Destination',
    'destination-label-change': 'Changer la destination',
    'destination-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de destination',
    'destination-placeholder': 'Lieu, adresse ou arrêt',
    'dialog-return-to-map': 'Retour à la carte',
    'digitransit-platform': 'Plateforme Digitransit',
    'disclaimer': 'Disclaimer',
    disruption: 'Perturbation',
    'disruption-info': 'Informations sur les perturbations',
    'disruption-info-no-alerts': "Aucune perturbation n'est répertoriée",
    'disruption-info-route-no-alerts': 'La ligne circule normalement',
    disruptions: 'Perturbations',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-under': 'Distance inférieure à {distance} m',
    earlier: 'Plus tôt',
    'edit-favourites': 'Éditer les favoris',
    explanations: 'Cause',
    'extra-info': 'Informations complémentaires',
    'favourite-target': 'Destination favorite',
    ferry: 'Bâteau',
    'ferry-with-route-number': 'Bâteau {routeNumber} {headSign}',
    'footer-feedback': 'Donner votre avis',
    'footer-link-to-privacy-policy': 'Disclaimer',
    'frontpage': 'Page de garde',
    'generic-error': "Une erreur s'est produite",
    'geolocate-yourself': 'Se géolocaliser',
    'geolocation-denied-heading': 'Comment souhaitez-vous démarrer ?',
    'geolocation-denied-text':
      "La géolocalisation n'est pas autorisée. Merci de vérifier votre navigateur/téléphone",
    'geolocation-failed-heading': 'Géolocalisation échouée',
    'geolocation-failed-text':
      'Votre navigateur ne supporte pas le positionnement',
    'geolocation-prompt-text':
      "Merci d'autoriser l'utilisation de votre position.",
    'geolocation-timeout-heading':
      'Le positionnement prend plus de temps que prévu.',
    'geolocation-timeout-text':
      'Merci de sélectionner une origine ou réessayer plus tard.',
    'give-destination': 'Entrer votre destination',
    'give-name-to-location': 'Donner un nom à cet endroit',
    'give-origin': 'Entrer votre point de départ',
    'give-position': 'Indiquer votre position ou un lieu dans le champ de recherche.',
    'give-route': 'Entrer une ligne',
    'guide':'Guide',
    'hour-short': 'h',
    'how-to-rate-service': 'Comment noteriez-vous ce service ?',
    'http://www.muoversiatorino.it/it/servizio':'http://www.muoversiatorino.it/en/about',
    'http://www.muoversiatorino.it/it/faq':'http://www.muoversiatorino.it/en/faq',
    'http://www.muoversiatorino.it/it/disclaimer':'http://www.muoversiatorino.it/en/disclaimer',
    'http://www.5t.torino.it':'http://www.5t.torino.it/en/',
    'in-addition': 'En plus',
    inquiry: 'Répondre aux questions pour développer le service',
    instructions: 'Instructions',
    'itinerary-feedback-button': 'Envoyez votre avis',
    'itinerary-feedback-message': 'Avez-vous trouvé ce que vous cherchiez ?',
    'itinerary-feedback-placeholder': 'Description (optionnel)',
    'itinerary-hide-stops': 'Cacher les arrêts',
    'itinerary-page.description': 'Itinéraire',
    'itinerary-page.title': 'Itinéraire',
    'itinerary-summary-page.description': 'Trajet recommandé',
    'itinerary-summary-page.title': 'Trajet recommandé',
    'itinerary-ticket.title': 'Titre de transport requis',
    'itinerary-tickets.title': 'Titres de transport requis',
    'itinerary-time.title': 'Duréé',
    'itinerary-walk.title': 'Distance totale de marche',
    'journeyplanner.title': 'Digitransit for Paris',
    later: 'Plus tard',
    leaves: 'Départ',
    'leaving-at': 'Départ',
    'likely-to-recommend':
      'A quel point recommandriez vous ce service à un ami ou un collègue ?',
    'likely-to-use':
      'A quel point utiliseriez-vous ce service plutôt que le service actuel ?',
    loading: 'Chargement',
    locate: 'Localiser',
    'location-examples': 'Gare de Lyon, Longjumeau, Rue du Bac, Châtelet,...',
    'location-from-map': 'Emplacement sélectionné',
    login: 'Se connecter',
    'long-transfers-margin': '12 min',
    'main-menu-label-open': 'ouvrez le menu principal',
    'main-mode': 'je préfère commencer ',
    'map': 'Carte',
    'map-layer-citybike': 'Sharing mobility',
    'map-layer-park-and-ride': 'Park & ride sites',
    'map-layer-stop-bus': 'Bus stops',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-tram': 'Tram stops',
    'map-layer-terminal-bus': 'Bus terminals',
    'map-layer-terminal-rail': 'Train stations',
    'map-layer-terminal-subway': 'Subway stations',
    'map-layer-ticket-sales-machine': 'Ticket machines',
    'map-layer-ticket-sales-point': 'Travel Card top up points',
    'messagebar-label-close-message-bar': 'Fermer le message',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Ligne',
    'more-settings': 'Plus de paramètres',
    navigate: 'Voyager',
    'near-you': 'A proximité',
    nearest: 'Le plus proche',
    'network-error': 'Erreur réseau',
    next: 'Suivant',
    'no-bike-allowed-popup-train':
      'Bicycles are allowed on commuter trains but on long distance trains you have to pay an extra fee and it might require a reservation.',
    'no-bike-allowed-popup-tram-bus':
      "Les vélos ne sont pas autorisés dans les bus ou les trams. Si vous utilisez ces véhicules sur votre trajet, laissez le vélo à l'arrêt ou à un Parc Relais.",
    'no-favourite-locations': 'Ajoutez vos emplacements les plus utilisés et les boutons planifient votre itinéraire tout de suite.',
    'no-favourites': 'Vous pouvez cliquer sur les étoiles pour ajouter une ligne à vos favoris. Après l\'ajout, ceux-ci sont disponibles dans l\'onglet Favoris de l\'interface',
    'no-position': 'Aucune position',
    'no-position-no-stops':
      'Localisation inconnue, impossible de proposer les arrêts à proximité.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      "Impossible de trouver un itinéraire dans cette zone. Changer le point de départ ou d'arrivée.",
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'The origin and the destination are close to each other. Please change either location.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Choisir un point de départ et une destination',
    'no-transfers-margin': 'Aucune',
    'no-trips-found': "Il n'y a pas de trajet possible au jour choisi.",
    now: 'Maintenant',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Sans arrêt} =1 {{number} arrêt} other {{number} arrêts} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minute} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      "En vous connectant, vos favoris seront sauvegardés et accessibles depuis d'autres appareils",
    'option-default': 'Par défaut',
    'option-least': 'Le moins',
    'option-less': 'Moins',
    'option-more': 'Plus',
    'option-most': 'Le plus',
    or: 'ou',
    origin: 'Départ',
    'origin-label-change': 'Changer le départ',
    'origin-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de départ',
    'origin-placeholder': 'Lieu, adresse ou arrêt',
    'own-position': 'Position actuelle',
    'page-not-found': 'Page non trouvée',
    'park-and-ride': 'Parc Relais',
    'park-and-ride-availability': 'Disponibilité du Parc Relais',
    'pay-attention': 'Faites attention',
    'park-and-ride-unknown': 'Capacité inconnue',
    'pick-icon': 'Sélectionner une icône',
    'pick-mode': 'Sélectionner un mode de transport',
    'pick-mode-airplane-disabled': 'Flight not selected',
    'pick-mode-airplane-enabled': 'Flight selected',
    'pick-mode-bus-disabled': 'Bus not selected',
    'pick-mode-bus-enabled': 'Bus selected',
    'pick-mode-citybike-disabled': 'Citybike not selected',
    'pick-mode-citybike-enabled': 'Citybike selected',
    'pick-mode-ferry-disabled': 'Ferry not selected',
    'pick-mode-ferry-enabled': 'Ferry selected',
    'pick-mode-rail-disabled': 'Rail not selected',
    'pick-mode-rail-enabled': 'Rail selected',
    'pick-mode-selected': 'Selected transport modes',
    'pick-mode-subway-disabled': 'Metro not selected',
    'pick-mode-subway-enabled': 'Metro selected',
    'pick-mode-tram-disabled': 'Tram not selected',
    'pick-mode-tram-enabled': 'Tram selected',
    'place-route-or-keyword': 'Lieu, ligne ou mot-clé',
    'platform-num': 'Quai {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'prefer-walking': 'Préférer la marche',
    'portaleWeb': 'Portail Web',
    'previous': 'Précédent',
    'print': 'Imprimer',
    'privacy':'privacy',
    rail: 'Train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    realtime: 'temps réel',
    'realtime-matching': 'Intégration du temps-réel',
    'rent-cycle-at': 'Décrocher un vélo à la station {station}',
    'required-ticket': 'Titre de transport requis',
    'return-cycle-to': 'Retourner la vélo à la station {station}',
    'right-now': 'Immédiatement',
    route: 'Ligne',
    'route-default': 'Paramètres par défaut',
    'route-destination-arrives': 'Arrivée / Terminus',
    'route-fastest': 'Trajet le plus rapide',
    'route-from-here': "Partir d'ici",
    'route-guide': 'Guide de trajet',
    'route-here': 'Arriver ici',
    'route-least-transfers': 'Éviter les correspondances',
    'route-least-walking': 'Éviter la marche',
    'route-not-possible':
      'Impossible de calculer un itinéraire. Information technique : "{error}"',
    'route-page.description': 'Ligne {shortName}, {longName}',
    'route-page.title': 'Ligne {shortName}',
    'route-page.title-short': 'Ligne',
    'route-prefer-greenways': 'Préférer les pistes cyclables',
    'route-prefer-illuminated': 'Préférer les routes éclairées',
    'route-prefer-paved': 'Préférer les routes pavées',
    'route-prefer-walking-routes': 'Préférer les rues piétonnes',
    'route-prefer-winter-maintenance': 'Préférer les routes déneigées',
    'route-preferences': "Préférences d'itinéraire",
    'route-public-transport-with-bicycle': 'Vélo et transport public',
    'route-saved-settings': 'Paramètres personnalisés',
    'route-stop-or-keyword': 'Ligne, arrêt ou mot-clé',
    routes: 'Lignes',
    run: 'Lancer',
    save: 'Enregistrer',
    'scooter-availability-short': 'Scooter {network}',
    search: 'Recherche',
    'search-destination': 'Entrer votre point de destination',
    'search-destination-results-but-no-search':
      "Voir les résultats de l'onglet 'Destination'",
    'search-no-results': 'Aucun résultat',
    'search-origin': 'Entrer votre point de départ',
    'search-position': 'Rechercher la position',
    'search-search-results-but-no-destination':
      "Voir les résultats de l'onglet 'Ligne, arrêt ou mot-clé'",
    'searching-position': 'Localisation en cours...',
    'select-map-layers-header': "Qu'afficher sur la carte ?",
    'select-position': 'Utiliser une localisation précédente',
    send: 'Envoyer',
    settings: 'Réglages',
    'settings-label-change': 'Changer les paramètres',
    'settings-reset': 'Remettre à zéro les paramètres',
    'settings-savebutton': 'Enregistrer les paramètres',
    'settings-saved': 'Paramètres enregistrés !',
    share: 'Partager',
    'show-departures': 'Afficher les départs',
    'show-more': 'Afficher plus',
    'show-routes': 'Afficher les lignes',
    'skip-positioning': 'Ignorer le positionnement',
    'skip-to-content': 'Skip to content',
    slow: 'Lent',
    'specify-location': 'Indiquer un endroit',
    'splash-choose': 'Choisir un point de départ',
    'splash-locating': 'Localisation...',
    'splash-or-choose': 'Ou choisir parmi ces destinations',
    'splash-please-allow-positioning':
      'Ce service fonctionne mieux en autorisant la géolocalisation.',
    'splash-use-positioning': 'Utiliser la position',
    'splash-welcome': 'Comment souhaitez-vous démarrer ?',
    'splash-you-can-also': 'Vous pouvez aussi',
    stop: 'Arrêt',
    'stop-number': "Identifiant d'arrêt",
    'stop-page.description': 'Arrêt - {name} {code}, {desc}',
    'stop-page.title': 'Arrêt - {name} {code}',
    'stop-page.title-short': 'Arrêt',
    stops: 'Arrêts',
    'street-mode-bicycle': 'Vélo',
    'street-mode-car': 'Voiture',
    'street-mode-car_park': 'Parc relais',
    'street-mode-public_transport': 'Transport public',
    'street-mode-walk': 'Marche',
    street: 'Street',
    subway: 'Métro',
    'subway-with-route-number': 'Métro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Itinéraire proposé',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal - {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'CGU',
    'ticket-and-price-info': 'Titres et informations tarifaires',
    'ticket-single-adult': 'Adulte',
    'ticket-type-none': 'Pas de limite tarifaire',
    tickets: 'Titres de transport',
    time: 'Heure',
    timetable: 'Fiche horaire',

    'to-stop': "à l'arrêt",
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    tram: 'Tramway',
    'tram-with-route-number': 'Tramway {routeNumber} {headSign}',
    transfers: 'Correspondances',
    'transfers-allowed': 'Autoriser les correspondances',
    'transfers-margin': 'Marge de correspondance',
    'trip-co2-emissions': 'Émissions de CO2',
    'trip-information': 'Informations de voyage',
    'trip-page.description': 'Ligne {shortName}, {longName}',
    'trip-page.title': 'Ligne {shortName}',
    'try-again': 'Réessayer',
    unpreferred: 'Éviter les lignes',
    'use-citybike': 'Utiliser',
    'use-national-service':
      'Vous pouvez aussi utiliser le service national disponible à',
    'use-own-position': 'Utiliser la position actuelle',
    'using-modes': 'Modes de transport',
    'very-likely': 'Très probable',
    'very-unlikely': 'Très improbable',
    'via-leg-stop-duration': "Attente de {stayDuration} à l'étape",
    'via-point': 'Étape',
    'via-point-index': 'Étape {index}',
    'viapoint-slack-amount': "Durée de l'étape",
    'view-route': "Voir l'itinéraire",
    wait: 'Attendre',
    'wait-amount-of-time': 'Attendre {duration}',
    walk: 'marche',
    'walk-distance-duration': 'Marcher {distance} ({duration})',
    walking: 'Marcher',
    'walking-speed': 'Vitesse de marche',
    'warning-call-agency':
      "La ligne {routeName} est à la demande, et doit être réservée à l'avance",
    'warning-call-agency-no-route':
      "Uniquement à la demande. Doit être réservé à l'avance",
    'weather-at-destination': 'Météo à destination',
    yesterday: 'Hier',
    'your-favourites': 'Favoris',
    zones: 'Zones tarifaires',
  },

};

export default translations;
