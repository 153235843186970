import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';
import { PREFIX_ROUTES } from '../util/path';
import RouteNumber from './RouteNumber';
import Icon from './Icon';
import IconWithCaution from './IconWithCaution';
import { FormattedMessage } from 'react-intl';


export default function RouteHeader(props) {
  const mode = props.route ? props.route.mode.toLowerCase() : 'bus';

  const trip = props.trip ? (
    <span className="route-header-trip">
      {/* 5t {props.trip.substring(0, 2)}:{props.trip.substring(2, 4)} → */}
      matricola {props.trip}
    </span>
  ) : (
    ''
  );

  let wcIcon
  if (props.accessible === 'POSSIBLE' )
    wcIcon= <Icon key='wc' className='wheelchair' img="icon-icon_wheelchair" />
  else if (props.accessible === 'NOT_POSSIBLE')
    wcIcon= <IconWithCaution key='wc' className='wheelchair' img="icon-icon_wheelchair" />

  const accessibleIcon = props.accessible ?
    (<span className="route-header-accessible-trip">
        {wcIcon}
    </span>) : '';

  const routeLineText = ` ${props.route && props.route.shortName || ''}`;

  // DT-3331: added query string sort=no to Link's to
  // 5T  added span around routeLineText to get around leflet css rule: leaflet-container a {color: #0078A8}
  const routeLine =
    props.trip && props.pattern ? (
      <Link
        to={`/${PREFIX_ROUTES}/${props.route.gtfsId}/stops/${
          props.pattern.code
        }?sort=no`}
      >
        <span className={mode}>{routeLineText}</span>
      </Link>
    ) : (
      routeLineText
    );

  return (
    <div className={cx('route-header', props.className)}>
      <h1 className={mode}>
        <RouteNumber mode={mode} text={routeLine} gtfsId={'-'/*5t non usato props.route.gtfsId*/} />
        {trip}
        {accessibleIcon}
      </h1>
      {props.full &&
        <div style={{ color:'#d94434', margin: '0 0 6px 4px'}}>
          <Icon img="icon-icon_caution" width={2.1} height={2.1} />
          <span style={{fontSize: 11, marginLeft: '6px', verticalAlign: 'super'}}>
          <FormattedMessage
            id="trip-full"
            defaultMessage="The vehicle is full"
          />
          </span>
        </div>
      }
    </div>
  );
}

RouteHeader.propTypes = {
  route: PropTypes.shape({
    gtfsId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    shortName: PropTypes.string,
  }).isRequired,
  trip: PropTypes.string,
  pattern: PropTypes.shape({ code: PropTypes.string.isRequired }),
  className: PropTypes.string,
  accessible: PropTypes.string,
};
